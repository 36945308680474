import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
const Test = (props) => {
  const { data } = props
  const allPosts = data.allMdx.edges
  const emptyQuery = ''
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })
  const handleInputChange = (event) => {
    //console.log(event.target.name)
    const query = event.target.value
    //const query = event.target.name
    const { data } = props
    const posts = data.allMdx.edges || []
    const filteredData = posts.filter((post) => {
      const { category, title, keywords } = post.node.frontmatter
      return (
        category.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        (keywords &&
          keywords.join('').toLowerCase().includes(query.toLowerCase()))
      )
    })
    setState({
      query,
      filteredData,
    })
  }
  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts
  return (
    <>
      <h1 style={{ textAlign: `center` }}>Writing</h1>
      <div className="searchBox">
        <input
          className="searchInput"
          type="text"
          aria-label="Search"
          placeholder="Type to filter posts..."
          onChange={handleInputChange}
        />
      </div>
      <div>
        <button name="thai" onClick={handleInputChange}>
          Thai
        </button>
        <button name="misc" onClick={handleInputChange}>
          Misc
        </button>
      </div>
      {posts.map(({ node }) => {
        const { excerpt } = node
        const { slug } = node.fields
        const { title, date, meta_description } = node.frontmatter
        return (
          <article key={slug}>
            <header>
              <h2>
                <Link to={slug}>{title}</Link>
              </h2>
              <p>{date}</p>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: meta_description || excerpt,
                }}
              />
            </section>
            <hr />
          </article>
        )
      })}
    </>
  )
}
export default Test
export const pageQuery = graphql`
  query foodBlogList2 {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/blog/food/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            category
            rowSpan
            colSpan
            cookingTime
            keywords
            prepTime
            backsideDescription
            last_updated(formatString: "MMMM Do, YYYY")
            date(formatString: "MMMM Do, YYYY")
            author
            imageMinHeight
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
          slug
        }
      }
    }
  }
`
